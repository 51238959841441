import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/handbook/post-layout/post-layout-primary.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "philosophy",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#philosophy",
        "aria-label": "philosophy permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Philosophy`}</h2>
    <p>{`Students learn language, learn through language, and learn about language in an environment where they explore concepts, solve problems, organise information, share discoveries, formulate hypotheses, and explain ideas.`}</p>
    <p>{`The school promotes a language philosophy whereby:`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`All students work toward speaking, reading, and writing at least two languages fluently. The acquisition of a second language provides students with the opportunity to grow beyond a single cultural community.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`The school encourages students to use English for social interactions but also permits students to use their home language for that purpose.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Language develops over time through purposeful listening, speaking, reading, and writing tasks across all subjects.`}</p>
      </li>
    </ol>
    <h2 {...{
      "id": "language-of-instruction",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#language-of-instruction",
        "aria-label": "language of instruction permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Language of Instruction`}</h2>
    <p>{`English is the primary medium of instruction. All PSI teachers support the learning of English through modeling and reinforcement in curricular and extracurricular activities. The school maintains an English-only policy in all classrooms (other than Additional Language classes) and expects students, teachers, and support staff to use only English during instruction. Students may occasionally serve as translators for other students who do not yet have enough English to understand directions or content. These translations occur only at teacher direction.`}</p>
    <p>{`PSI recognises every teacher is a language teacher and is the responsibility of faculty and staff. Through modeling, explicit teaching and integration, language teaching is contextualised, purposeful and relevant. The primary focus of developing English language is a focus on ways in which language is used to achieve particular results in given circumstances, be they academic or social.`}</p>
    <h2 {...{
      "id": "second-language",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#second-language",
        "aria-label": "second language permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Second Language`}</h2>
    <p>{`The school offers instruction in languages other than English as student needs and staff availability dictate. A variety of levels of language are offered, both through in-class differentiation and / or through classes split by competency level. Currently, the primary school offers Russian, Spanish, French and Ukrainian. Additional languages may also be offered in the Extra Curricular Activities programme.`}</p>
    <p>{`As the purpose of gaining an additional language is to gain proficiency and be able to communicate, students are strongly encouraged to stay within their chosen language. It is understood that children and family circumstances change. Therefore children will be allowed to switch languages on a case-by-case basis at the beginning of the school year, with the approval of the Language teacher and the building principal.`}</p>
    <h2 {...{
      "id": "language-profile-development",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#language-profile-development",
        "aria-label": "language profile development permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Language Profile Development`}</h2>
    <p>{`Literacy in the student’s mother tongue is recognised as an important part of the development of students’ self-esteem and cultural identity and is a key component of successful learning of a second language. The school supports and encourages opportunities for mother tongue development in the community and in the home. Development of mother tongue resources receives budgetary support. Parents and students have access to school resources in order to support mother tongue development at home. In the Primary School, Mother Tongue instruction is part of the Extra Curricular Activities Programme and the school actively encourages these classes. At both primary and secondary levels, the school financially supports a Ukrainian Mother Tongue programme taught on weekends. Russian and Ukrainian Mother Tongue is offered as part of the Additional Language programme and follows a curriculum based on PSI Standards and Practices.`}</p>
    <h2 {...{
      "id": "admissions",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#admissions",
        "aria-label": "admissions permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Admissions`}</h2>
    <p>{`The Primary school accepts children with all levels of English proficiency, although non-speakers are limited to the school’s capacity to meet such needs.`}</p>
    <p>{`It is the policy of PSI to establish a language profile for all incoming students. Prospective students will complete a home language survey during registration. This profile provides teachers with critical information about each student’s proficiency and needs in languages used at school. The profile also contains information about languages used outside of school. The language profile is used to determine the support needs of each student. Parents have the right to view their child’s language profile. The profiles are maintained in the school’s database until the student graduates or leaves to attend another school.`}</p>
    <h2 {...{
      "id": "placement",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#placement",
        "aria-label": "placement permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Placement`}</h2>
    <h4 {...{
      "id": "kindergarten-eal",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#kindergarten-eal",
        "aria-label": "kindergarten eal permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Kindergarten EAL`}</h4>
    <p>{`The homeroom Kindergarten programme supports language acquisition for all students. Children who qualify receive additional EAL support in the homeroom classroom.`}</p>
    <h4 {...{
      "id": "grades-1---5",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#grades-1---5",
        "aria-label": "grades 1   5 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Grades 1 - 5`}</h4>
    <p>{`Based on WIDA testing results, English Language Learners with low levels of English are placed in an EAL class instead of an Additional Language class. Similarly, children experiencing learning difficulties may receive Resource Support at this time.`}</p>
    <p>{`All other children may choose between Ukrainian, Spanish, French, Russian or Mother Tongue Russian. Internal assessments determine whether a child enters Russian for Native or Non-Native speakers.`}</p>
    <h2 {...{
      "id": "assessment",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#assessment",
        "aria-label": "assessment permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Assessment`}</h2>
    <p>{`PSI employs a range of formative and summative assessment tools in all languages. Evidence of language development will be collected from class work, informal and formal observations, and standardised language assessments such as WIDA and Measure of Academic Progress (MAP). Samples of student work from subject areas may also serve as indicators of progress in English.`}</p>
    <h2 {...{
      "id": "primary-reports",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#primary-reports",
        "aria-label": "primary reports permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Primary Reports`}</h2>
    <p>{`On report cards, students taking French, Spanish, Russian or Ukrainian will be marked as Beginning, Developing, Proficient or Extending within their developmental age and in the case of Russian, their placement group. EAL children taking EAL will receive marks by the EAL teacher.`}</p>
    <h2 {...{
      "id": "exiting-from-eal-support-and-ongoing-monitoring",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#exiting-from-eal-support-and-ongoing-monitoring",
        "aria-label": "exiting from eal support and ongoing monitoring permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Exiting from EAL support and ongoing monitoring`}</h2>
    <p>{`To be exited from EAL support, students must meet the criteria established by the EAL department in collaboration with mainstream teachers.`}</p>
    <p>{`Students who exit the EAL programme are monitored for at least one semester and up to one year in order to determine progress. If academic progress stalls or regresses, the EAL department reexamines the student to determine if re-entry is necessary.`}</p>
    <h2 {...{
      "id": "at-risk-students",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#at-risk-students",
        "aria-label": "at risk students permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`At Risk Students`}</h2>
    <p>{`If children are not exhibiting expected progress in English attainment, the homeroom and EAL teachers meet with the Learning Resource team to see if additional issues are at play. Every effort will be made to accommodate student learning.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      